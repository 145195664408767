<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">流程优化软件</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        理顺公司的信息流
        <br />
        理顺部门之间的接口
        <br />
        理顺岗位职责
        <br />
        优化管理流程，提高工作效率
        <br />
        为科学的业绩考核奠定基础
      </p>
      <br />
      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        流程优化找不到切入点
        <br />
        流程优化花费时间太长
        <br />
        优化后的内容难以与数字化转营接口
      </p>
      <br />
      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        1. 对流程进行活动分解
        <br />
        2. 对活动进行定义
        <br />
        3. 发现文件规定中的疏漏
        <br />
        4. 通过筛选发现问题
        <br />
        5. 通过检讨表格进行优化
        <br />
        6. 理顺接口
      </p>
      <br />
      <p class="PTitle LeftBor">成果展示：</p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/72.png"
        class="SonImgW"
        style="width: 730px"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>